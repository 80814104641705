<template>
  <BackofficeBase :loader_prop="loader_prop">
    Delete User
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: 'AdminUserDelete',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop: true
    }
  },
  async mounted() {
    let backendModel = new BackendModel()
    await backendModel.backendRequest("/Api/service/backoffice/backoffice_user_delete", {id : this.$route.query.id})
    await this.$router.push({ path: '/backoffice/backoffice_users' })
  }
}
</script>

<style>
.item_action {
  font-size: 10px;
}
</style>
